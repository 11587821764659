.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card {
  text-align: center;
    border-radius: 25px!important;
    height: 100%;
    padding: 3rem;
    background-image: linear-gradient(
160deg
, rgba(246, 249, 249, 0.4), rgba(110, 196, 223, 0.1));
    border: 1px solid rgba(110, 195, 223, 0.25);
    box-shadow: 5px 5px 9px 0px rgb(41 151 188 / 20%);
}

.btn.btn-primary {
  background-color: #0079c2;
  border-radius: 25px;
  border-color: #0079c2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h2 {
  font-family: 'Bookman Old Style', serif;
  color: #0079c2;
}

a.white {
  color: white!important;
}

.ul{
  padding: 0px;
  margin: 0px;
}

.ul li{
  padding: 10;
  text-align: left;
  list-style-type: none;
}

.ul li a{
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
